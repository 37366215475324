const static_country = {
    continent: [{
            "name": "قاره آسیا",
            "name_en": "asia",
        },
        {
            "name": "قاره اروپا",
            "name_en": "europe",
        },
        {
            "name": "قاره آمریکا",
            "name_en": "america",
        },
        {
            "name": "قاره آفریقا",
            "name_en": "africa",
        },
        {
            "name": "قاره اقیانوسیه",
            "name_en": "oceania",
        },
    ],
    country_name: {
        "AF": {
            "en": "Afghanistan",
            "fa": "افغانستان"
        },
        "AL": {
            "en": "Albania",
            "fa": "آلبانی"
        },
        "DZ": {
            "en": "Algeria",
            "fa": "الجزایر"
        },
        "AS": {
            "en": "American Samoa",
            "fa": "ساموای امریکا"
        },
        "AD": {
            "en": "Andorra",
            "fa": "آندورا"
        },
        "AO": {
            "en": "Angola",
            "fa": "آنگولا"
        },
        // "AI": {
        //     "en": "Anguilla",
        //     "fa": "آنگیل"
        // },
        "AQ": {
            "en": "Antarctica",
            "fa": "جنوبگان"
        },
        "AG": {
            "en": "Antigua and Barbuda",
            "fa": "آنتیگوا و باربودا"
        },
        "AR": {
            "en": "Argentina",
            "fa": "آرژانتین"
        },
        "AM": {
            "en": "Armenia",
            "fa": "ارمنستان"
        },
        "AW": {
            "en": "Aruba",
            "fa": "آروبا"
        },
        "AU": {
            "en": "Australia",
            "fa": "استرالیا"
        },
        "AT": {
            "en": "Austria",
            "fa": "اتریش"
        },
        "AZ": {
            "en": "Azerbaijan",
            "fa": "جمهوری آذربایجان"
        },
        "BS": {
            "en": "Bahamas",
            "fa": "باهاما"
        },
        "BH": {
            "en": "Bahrain",
            "fa": "بحرین"
        },
        "BD": {
            "en": "Bangladesh",
            "fa": "بنگلادش"
        },
        "BB": {
            "en": "Barbados",
            "fa": "باربادوس"
        },
        "BY": {
            "en": "Belarus",
            "fa": "بیلوروسی"
        },
        "BE": {
            "en": "Belgium",
            "fa": "بلژیک"
        },
        "BZ": {
            "en": "Belize",
            "fa": "بلیز"
        },
        "BJ": {
            "en": "Benin",
            "fa": "بنین"
        },
        "BM": {
            "en": "Bermuda",
            "fa": "برمودا"
        },
        "BT": {
            "en": "Bhutan",
            "fa": "بوتان"
        },
        "BO": {
            "en": "Bolivia",
            "fa": "بولیوی"
        },
        "BA": {
            "en": "Bosnia and Herzegovina",
            "fa": "بوسنی و هرزگوین"
        },
        "BW": {
            "en": "Botswana",
            "fa": "بوتسوانا"
        },
        "BV": {
            "en": "Bouvet Island",
            "fa": "جزیره بووت"
        },
        "BR": {
            "en": "Brazil",
            "fa": "برزیل"
        },
        "BQ": {
            "en": "British Antarctic Territory",
            "fa": null
        },
        // "IO": {
        //     "en": "British Indian Ocean Territory",
        //     "fa": "مستعمره‌های بریتانیا در اقیانوس هند"
        // },
        // "VG": {
        //     "en": "British Virgin Islands",
        //     "fa": "جزایر ویرجین بریتانیا"
        // },
        "BN": {
            "en": "Brunei",
            "fa": "برونئی"
        },
        "BG": {
            "en": "Bulgaria",
            "fa": "بلغارستان"
        },
        "BF": {
            "en": "Burkina Faso",
            "fa": "بورکینافاسو"
        },
        "BI": {
            "en": "Burundi",
            "fa": "بوروندی"
        },
        "KH": {
            "en": "Cambodia",
            "fa": "کامبوج"
        },
        "CM": {
            "en": "Cameroon",
            "fa": "کامرون"
        },
        "CA": {
            "en": "Canada",
            "fa": "کانادا"
        },
        "CT": {
            "en": "Canton and Enderbury Islands",
            "fa": null
        },
        "CV": {
            "en": "Cape Verde",
            "fa": "کیپ ورد"
        },
        "KY": {
            "en": "Cayman Islands",
            "fa": "جزایر کِیمن"
        },
        "CF": {
            "en": "Central african Republic",
            "fa": "جمهوری افریقای مرکزی"
        },
        "TD": {
            "en": "Chad",
            "fa": "چاد"
        },
        "CL": {
            "en": "Chile",
            "fa": "شیلی"
        },
        "CN": {
            "en": "China",
            "fa": "چین"
        },
        // "CX": {
        //     "en": "Christmas Island",
        //     "fa": "جزیره کریسمس"
        // },
        // "CC": {
        //     "en": "Cocos [Keeling] Islands",
        //     "fa": "جزایر کوکوس"
        // },
        "CO": {
            "en": "Colombia",
            "fa": "کلمبیا"
        },
        "KM": {
            "en": "Comoros",
            "fa": "کومورو"
        },
        "CG": {
            "en": "Congo - Brazzaville",
            "fa": "کنگو برازویل"
        },
        "CD": {
            "en": "Congo - Kinshasa",
            "fa": "کنگو کینشاسا"
        },
        // "CK": {
        //     "en": "Cook Islands",
        //     "fa": "جزایر کوک"
        // },
        "CR": {
            "en": "Costa Rica",
            "fa": "کاستاریکا"
        },
        "HR": {
            "en": "Croatia",
            "fa": "کرواسی"
        },
        "CU": {
            "en": "Cuba",
            "fa": "کوبا"
        },
        "CY": {
            "en": "Cyprus",
            "fa": "قبرس"
        },
        "CZ": {
            "en": "Czech Republic",
            "fa": "جمهوری چک"
        },
        "CI": {
            "en": "Côte d’Ivoire",
            "fa": "ساحل عاج"
        },
        "IC": {
            "en": "Côte d’Ivoire",
            "fa": "ساحل عاج"
        },
        "DK": {
            "en": "Denmark",
            "fa": "دانمارک"
        },
        "DJ": {
            "en": "Djibouti",
            "fa": "جیبوتی"
        },
        "DM": {
            "en": "Dominica",
            "fa": "دومینیک"
        },
        "DO": {
            "en": "Dominican Republic",
            "fa": "جمهوری دومینیکن"
        },
        "NQ": {
            "en": "Dronning Maud Land",
            "fa": null
        },
        "DD": {
            "en": "East Germany",
            "fa": null
        },
        "EC": {
            "en": "Ecuador",
            "fa": "اکوادر"
        },
        "EG": {
            "en": "Egypt",
            "fa": "مصر"
        },
        "SV": {
            "en": "El Salvador",
            "fa": "السالوادور"
        },
        "GQ": {
            "en": "Equatorial Guinea",
            "fa": "گینه استوایی"
        },
        "ER": {
            "en": "Eritrea",
            "fa": "اریتره"
        },
        "EE": {
            "en": "Estonia",
            "fa": "استونی"
        },
        "ET": {
            "en": "Ethiopia",
            "fa": "اتیوپی"
        },
        "FK": {
            "en": "Falkland Islands",
            "fa": "جزایر فالکلند"
        },
        "FO": {
            "en": "Faroe Islands",
            "fa": "جزایر فارو"
        },
        "FJ": {
            "en": "Fiji",
            "fa": "فیجی"
        },
        "FI": {
            "en": "Finland",
            "fa": "فنلاند"
        },
        "FL": {
            "en": "Finland",
            "fa": "فنلاند"
        },
        "FR": {
            "en": "France",
            "fa": "فرانسه"
        },
        "GF": {
            "en": "French Guiana",
            "fa": "گویان فرانسه"
        },
        "PF": {
            "en": "French Polynesia",
            "fa": "پلی‌نزی فرانسه"
        },
        "TF": {
            "en": "French Southern Territories",
            "fa": "مستعمره‌های جنوبی فرانسه"
        },
        "FQ": {
            "en": "French Southern and Antarctic Territories",
            "fa": null
        },
        "GA": {
            "en": "Gabon",
            "fa": "گابون"
        },
        "GM": {
            "en": "Gambia",
            "fa": "گامبیا"
        },
        "GE": {
            "en": "Georgia",
            "fa": "گرجستان"
        },
        "DE": {
            "en": "Germany",
            "fa": "آلمان"
        },
        "GH": {
            "en": "Ghana",
            "fa": "غنا"
        },
        "GI": {
            "en": "Gibraltar",
            "fa": "جبل‌الطارق"
        },
        "GR": {
            "en": "Greece",
            "fa": "یونان"
        },
        "GL": {
            "en": "Greenland",
            "fa": "گروئنلند"
        },
        "GD": {
            "en": "Grenada",
            "fa": "گرانادا"
        },
        "GP": {
            "en": "Guadeloupe",
            "fa": "گوادلوپ"
        },
        "GU": {
            "en": "Guam",
            "fa": "گوام"
        },
        "GT": {
            "en": "Guatemala",
            "fa": "گواتمالا"
        },
        // "GG": {
        //     "en": "Guernsey",
        //     "fa": "گرنزی"
        // },
        "GN": {
            "en": "Guinea",
            "fa": "گینه"
        },
        "GW": {
            "en": "Guinea-Bissau",
            "fa": "گینه بیسائو"
        },
        "GY": {
            "en": "Guyana",
            "fa": "گویان"
        },
        "HT": {
            "en": "Haiti",
            "fa": "هاییتی"
        },
        // "HM": {
        //     "en": "Heard Island and McDonald Islands",
        //     "fa": "جزیره هرد و جزایر مک‌دونالد"
        // },
        "HN": {
            "en": "Honduras",
            "fa": "هندوراس"
        },
        "HK": {
            "en": "Hong Kong",
            "fa": "هنگ کنگ"
        },
        "HU": {
            "en": "Hungary",
            "fa": "مجارستان"
        },
        "IS": {
            "en": "Iceland",
            "fa": "ایسلند"
        },
        "IN": {
            "en": "India",
            "fa": "هند"
        },
        "ID": {
            "en": "Indonesia",
            "fa": "اندونزی"
        },
        "IR": {
            "en": "Iran",
            "fa": "ایران"
        },
        "IQ": {
            "en": "Iraq",
            "fa": "عراق"
        },
        "IE": {
            "en": "Ireland",
            "fa": "ایرلند"
        },
        "IM": {
            "en": "Isle of Man",
            "fa": "جزیره مان"
        },
        // "IL": {
        //     "en": "Israel",
        //     "fa": "اسرائیل"
        // },
        "IT": {
            "en": "Italy",
            "fa": "ایتالیا"
        },
        "JM": {
            "en": "Jamaica",
            "fa": "جامائیکا"
        },
        "JP": {
            "en": "Japan",
            "fa": "ژاپن"
        },
        // "JE": {
        //     "en": "Jersey",
        //     "fa": "جرسی"
        // },
        "JT": {
            "en": "Johnston Island",
            "fa": null
        },
        "JO": {
            "en": "Jordan",
            "fa": "اردن"
        },
        "KZ": {
            "en": "Kazakhstan",
            "fa": "قزاقستان"
        },
        "KE": {
            "en": "Kenya",
            "fa": "کنیا"
        },
        "KI": {
            "en": "Kiribati",
            "fa": "کیریباتی"
        },
        "KW": {
            "en": "Kuwait",
            "fa": "کویت"
        },
        "KG": {
            "en": "Kyrgyzstan",
            "fa": "قرقیزستان"
        },
        "LA": {
            "en": "Laos",
            "fa": "لائوس"
        },
        "LV": {
            "en": "Latvia",
            "fa": "لتونی"
        },
        "LB": {
            "en": "Lebanon",
            "fa": "لبنان"
        },
        "LS": {
            "en": "Lesotho",
            "fa": "لسوتو"
        },
        "LR": {
            "en": "Liberia",
            "fa": "لیبریا"
        },
        "LY": {
            "en": "Libya",
            "fa": "لیبی"
        },
        "LI": {
            "en": "Liechtenstein",
            "fa": "لیختن‌اشتاین"
        },
        "LT": {
            "en": "Lithuania",
            "fa": "لیتوانی"
        },
        "LU": {
            "en": "Luxembourg",
            "fa": "لوکزامبورگ"
        },
        "MO": {
            "en": "Macau SAR China",
            "fa": "ماکائو، ناحیه حکومتی چین"
        },
        "MK": {
            "en": "Macedonia",
            "fa": "مقدونیه"
        },
        "MG": {
            "en": "Madagascar",
            "fa": "ماداگاسکار"
        },
        "MW": {
            "en": "Malawi",
            "fa": "مالاوی"
        },
        "MY": {
            "en": "Malaysia",
            "fa": "مالزی"
        },
        "MV": {
            "en": "Maldives",
            "fa": "مالدیو"
        },
        "ML": {
            "en": "Mali",
            "fa": "مالی"
        },
        "MT": {
            "en": "Malta",
            "fa": "مالت"
        },
        "MH": {
            "en": "Marshall Islands",
            "fa": "جزایر مارشال"
        },
        "MQ": {
            "en": "Martinique",
            "fa": "مارتینیک"
        },
        "MR": {
            "en": "Mauritania",
            "fa": "موریتانی"
        },
        "MU": {
            "en": "Mauritius",
            "fa": "موریس"
        },
        "YT": {
            "en": "Mayotte",
            "fa": "مایوت"
        },
        "FX": {
            "en": "Metropolitan France",
            "fa": null
        },
        "MX": {
            "en": "Mexico",
            "fa": "مکزیک"
        },
        "FM": {
            "en": "Micronesia",
            "fa": "میکرونزی"
        },
        "MI": {
            "en": "Midway Islands",
            "fa": null
        },
        "MD": {
            "en": "Moldova",
            "fa": "مولداوی"
        },
        "MC": {
            "en": "Monaco",
            "fa": "موناکو"
        },
        "MN": {
            "en": "Mongolia",
            "fa": "مغولستان"
        },
        "ME": {
            "en": "Montenegro",
            "fa": "مونته‌نگرو"
        },
        // "MS": {
        //     "en": "Montserrat",
        //     "fa": "مونت‌سرات"
        // },
        "MA": {
            "en": "Morocco",
            "fa": "مراکش"
        },
        "MZ": {
            "en": "Mozambique",
            "fa": "موزامبیک"
        },
        "MM": {
            "en": "Myanmar [Burma]",
            "fa": "میانمار"
        },
        "NA": {
            "en": "Namibia",
            "fa": "نامیبیا"
        },
        // "NR": {
        //     "en": "Nauru",
        //     "fa": "نائورو"
        // },
        "NP": {
            "en": "Nepal",
            "fa": "نپال"
        },
        "NL": {
            "en": "Netherlands",
            "fa": "هلند"
        },
        // "AN": {
        //     "en": "Netherlands Antilles",
        //     "fa": "آنتیل هلند"
        // },
        "NT": {
            "en": "Neutral Zone",
            "fa": null
        },
        "NC": {
            "en": "New Caledonia",
            "fa": "کالدونیای جدید"
        },
        "NZ": {
            "en": "New Zealand",
            "fa": "نیوزیلند"
        },
        "NI": {
            "en": "Nicaragua",
            "fa": "نیکاراگوئه"
        },
        "NE": {
            "en": "Niger",
            "fa": "نیجر"
        },
        "NG": {
            "en": "Nigeria",
            "fa": "نیجریه"
        },
        // "NU": {
        //     "en": "Niue",
        //     "fa": "نیوئه"
        // },
        // "NF": {
        //     "en": "Norfolk Island",
        //     "fa": "جزیره نورفولک"
        // },
        "KP": {
            "en": "North Korea",
            "fa": "کره شمالی"
        },
        "VD": {
            "en": "North Vietnam",
            "fa": null
        },
        "MP": {
            "en": "Northern Mariana Islands",
            "fa": "جزایر ماریانای شمالی"
        },
        "NO": {
            "en": "Norway",
            "fa": "نروژ"
        },
        "OM": {
            "en": "Oman",
            "fa": "عمان"
        },
        "PC": {
            "en": "Pacific Islands Trust Territory",
            "fa": null
        },
        "PK": {
            "en": "Pakistan",
            "fa": "پاکستان"
        },
        "PW": {
            "en": "Palau",
            "fa": "پالائو"
        },
        "PS": {
            "en": "Palestinian",
            "fa": "فلسطین"
        },
        "PA": {
            "en": "Panama",
            "fa": "پاناما"
        },
        "PZ": {
            "en": "Panama Canal Zone",
            "fa": null
        },
        "PG": {
            "en": "Papua New Guinea",
            "fa": "پاپوا گینه نو"
        },
        "PY": {
            "en": "Paraguay",
            "fa": "پاراگوئه"
        },
        "YD": {
            "en": "People's Democratic Republic of Yemen",
            "fa": null
        },
        "PE": {
            "en": "Peru",
            "fa": "پرو"
        },
        "PH": {
            "en": "Philippines",
            "fa": "فیلیپین"
        },
        // "PN": {
        //     "en": "Pitcairn Islands",
        //     "fa": "پیتکایرن"
        // },
        "PL": {
            "en": "Poland",
            "fa": "لهستان"
        },
        "PT": {
            "en": "Portugal",
            "fa": "پرتغال"
        },
        "PR": {
            "en": "Puerto Rico",
            "fa": "پورتو ریکو"
        },
        "QA": {
            "en": "Qatar",
            "fa": "قطر"
        },
        "RO": {
            "en": "Romania",
            "fa": "رومانی"
        },
        "RU": {
            "en": "Russia",
            "fa": "روسیه"
        },
        "RW": {
            "en": "Rwanda",
            "fa": "رواندا"
        },
        // "RE": {
        //     "en": "Réunion",
        //     "fa": "ریونیون"
        // },
        "BL": {
            "en": "Saint Barthélemy",
            "fa": "سنت بارتلیمی"
        },
        // "SH": {
        //     "en": "Saint Helena",
        //     "fa": "سنت هلن"
        // },
        "KN": {
            "en": "Saint Kitts and Nevis",
            "fa": "سنت کیتس و نویس"
        },
        "LC": {
            "en": "Saint Lucia",
            "fa": "سنت لوسیا"
        },
        "MF": {
            "en": "Saint Martin",
            "fa": "سنت مارتین"
        },
        // "PM": {
        //     "en": "Saint Pierre and Miquelon",
        //     "fa": "سنت پیر و میکلون"
        // },
        "VC": {
            "en": "Saint Vincent and the Grenadines",
            "fa": "سنت وینسنت و گرنادین"
        },
        "WS": {
            "en": "Samoa",
            "fa": "ساموا"
        },
        "SM": {
            "en": "San Marino",
            "fa": "سان مارینو"
        },
        "SA": {
            "en": "Saudi Arabia",
            "fa": "عربستان سعودی"
        },
        "SN": {
            "en": "Senegal",
            "fa": "سنگال"
        },
        "RS": {
            "en": "Serbia",
            "fa": "صربستان"
        },
        "CS": {
            "en": "Serbia and Montenegro",
            "fa": "صربستان و مونته‌نگرو"
        },
        "SC": {
            "en": "Seychelles",
            "fa": "سیشل"
        },
        "SL": {
            "en": "Sierra Leone",
            "fa": "سیرالئون"
        },
        "SG": {
            "en": "Singapore",
            "fa": "سنگاپور"
        },
        "SK": {
            "en": "Slovakia",
            "fa": "اسلواکی"
        },
        "SI": {
            "en": "Slovenia",
            "fa": "اسلوونی"
        },
        "SB": {
            "en": "Solomon Islands",
            "fa": "جزایر سلیمان"
        },
        "SO": {
            "en": "Somalia",
            "fa": "سومالی"
        },
        "ZA": {
            "en": "South africa",
            "fa": "افریقای جنوبی"
        },
        // "GS": {
        //     "en": "South Georgia and the South Sandwich Islands",
        //     "fa": "جورجیای جنوبی و جزایر ساندویچ جنوبی"
        // },
        "KR": {
            "en": "South Korea",
            "fa": "کره جنوبی"
        },
        "ES": {
            "en": "Spain",
            "fa": "اسپانیا"
        },
        "LK": {
            "en": "Sri Lanka",
            "fa": "سری‌لانکا"
        },
        "SD": {
            "en": "Sudan",
            "fa": "سودان"
        },
        "SR": {
            "en": "Suriname",
            "fa": "سورینام"
        },
        // "SJ": {
        //     "en": "Svalbard and Jan Mayen",
        //     "fa": "اسوالبارد و جان ماین"
        // },
        "SZ": {
            "en": "Swaziland",
            "fa": "سوازیلند"
        },
        "SE": {
            "en": "Sweden",
            "fa": "سوئد"
        },
        "CH": {
            "en": "Switzerland",
            "fa": "سوئیس"
        },
        "EU": {
            "en": "Euro Zone",
            "fa": "منطقه اروپا"
        },
        "SY": {
            "en": "Syria",
            "fa": "سوریه"
        },
        // "ST": {
        //     "en": "São Tomé and Príncipe",
        //     "fa": "سائو تومه و پرینسیپه"
        // },
        "TW": {
            "en": "Taiwan",
            "fa": "تایوان"
        },
        "TJ": {
            "en": "Tajikistan",
            "fa": "تاجیکستان"
        },
        "TZ": {
            "en": "Tanzania",
            "fa": "تانزانیا"
        },
        "TH": {
            "en": "Thailand",
            "fa": "تایلند"
        },
        "TL": {
            "en": "Timor-Leste",
            "fa": "تیمور شرقی"
        },
        "TG": {
            "en": "Togo",
            "fa": "توگو"
        },
        // "TK": {
        //     "en": "Tokelau",
        //     "fa": "توکلائو"
        // },
        "TO": {
            "en": "Tonga",
            "fa": "تونگا"
        },
        "TT": {
            "en": "Trinidad and Tobago",
            "fa": "ترینیداد و توباگو"
        },
        "TN": {
            "en": "Tunisia",
            "fa": "تونس"
        },
        "TR": {
            "en": "Turkey",
            "fa": "ترکیه"
        },
        "TM": {
            "en": "Turkmenistan",
            "fa": "ترکمنستان"
        },
        "TC": {
            "en": "Turks and Caicos Islands",
            "fa": "جزایر ترک و کایکوس"
        },
        // "TV": {
        //     "en": "Tuvalu",
        //     "fa": "تووالو"
        // },
        // "UM": {
        //     "en": "U.S. Minor Outlying Islands",
        //     "fa": "جزایر کوچک دورافتاده ایالات متحده"
        // },
        "PU": {
            "en": "U.S. Miscellaneous Pacific Islands",
            "fa": null
        },
        "VI": {
            "en": "U.S. Virgin Islands",
            "fa": "جزایر ویرجین ایالات متحده"
        },
        "UG": {
            "en": "Uganda",
            "fa": "اوگاندا"
        },
        "UA": {
            "en": "Ukraine",
            "fa": "اوکراین"
        },
        "SU": {
            "en": "Union of Soviet Socialist Republics",
            "fa": null
        },
        "AE": {
            "en": "United Arab Emirates",
            "fa": "امارات متحده عربی"
        },
        "GB": {
            "en": "United Kingdom",
            "fa": "بریتانیا"
        },
        "US": {
            "en": "United States",
            "fa": "ایالات متحده آمریکا"
        },
        "ZZ": {
            "en": "Unknown or Invalid Region",
            "fa": "ناحیه نامشخص یا نامعتبر"
        },
        "UY": {
            "en": "Uruguay",
            "fa": "اوروگوئه"
        },
        "UZ": {
            "en": "Uzbekistan",
            "fa": "ازبکستان"
        },
        "VU": {
            "en": "Vanuatu",
            "fa": "وانواتو"
        },
        // "VA": {
        //     "en": "Vatican City",
        //     "fa": "واتیکان"
        // },
        "VE": {
            "en": "Venezuela",
            "fa": "ونزوئلا"
        },
        "VN": {
            "en": "Vietnam",
            "fa": "ویتنام"
        },
        "WK": {
            "en": "Wake Island",
            "fa": null
        },
        // "WF": {
        //     "en": "Wallis and Futuna",
        //     "fa": "والیس و فیوتونا"
        // },
        // "EH": {
        //     "en": "Western Sahara",
        //     "fa": "صحرای غربی"
        // },
        "YE": {
            "en": "Yemen",
            "fa": "یمن"
        },
        "ZM": {
            "en": "Zambia",
            "fa": "زامبیا"
        },
        "ZW": {
            "en": "Zimbabwe",
            "fa": "زیمبابوه"
        },
        // "AX": {
        //     "en": "Åland Islands",
        //     "fa": "جزایر آلاند"
        // }
    },
    country_locations: [{
            "name": "Iran",
            "code": "IR",
            "continent": "asia",
            "filename": "iran-islamic-republic-of"
        },
        {
            "name": "Afghanistan",
            "code": "AF",
            "continent": "asia",
            "filename": "afghanistan"
        },
        {
            "name": "Åland Islands",
            "continent": "europe",
            "code": "AX"
        },
        {
            "name": "Albania",
            "code": "AL",
            "continent": "europe",
            "filename": "albania"
        },
        {
            "name": "Algeria",
            "code": "DZ",
            "continent": "africa",
            "filename": "algeria"
        },
        {
            "name": "American Samoa",
            "code": "AS",
            "continent": "oceania"
        },
        {
            "name": "Andorra",
            "code": "AD",
            "continent": "europe",
            "filename": "andorra"
        },
        {
            "name": "Angola",
            "code": "AO",
            "continent": "africa",
            "filename": "angola"
        },
        {
            "name": "Anguilla",
            "code": "AI",
            "continent": "america"
        },
        {
            "name": "Antarctica",
            "continent": "Antarctica",
            "code": "AQ"
        },
        {
            "name": "Antigua and Barbuda",
            "code": "AG",
            "continent": "america",
            "filename": "antigua-and-barbuda"
        },
        {
            "name": "Argentina",
            "code": "AR",
            "continent": "america",
            "filename": "argentina"
        },
        {
            "name": "Armenia",
            "code": "AM",
            "continent": "europe",
            "filename": "armenia"
        },
        {
            "name": "Aruba",
            "code": "AW",
            "continent": "europe"
        },
        {
            "name": "Australia",
            "code": "AU",
            "continent": "oceania",
            "filename": "australia"
        },
        {
            "name": "Austria",
            "code": "AT",
            "continent": "europe",
            "filename": "austria"
        },
        {
            "name": "Azerbaijan",
            "code": "AZ",
            "continent": "europe",
            "filename": "azerbaijan"
        },
        {
            "name": "Bahamas",
            "code": "BS",
            "continent": "america",
            "filename": "bahamas"
        },
        {
            "name": "Bahrain",
            "code": "BH",
            "continent": "asia",
            "filename": "bahrain"
        },
        {
            "name": "Bangladesh",
            "code": "BD",
            "continent": "asia",
            "filename": "bangladesh"
        },
        {
            "name": "Barbados",
            "code": "BB",
            "continent": "america",
            "filename": "barbados"
        },
        {
            "name": "Belarus",
            "code": "BY",
            "continent": "europe",
            "filename": "belarus"
        },
        {
            "name": "Belgium",
            "code": "BE",
            "continent": "europe",
            "filename": "belgium"
        },
        {
            "name": "Belize",
            "code": "BZ",
            "continent": "america",
            "filename": "belize"
        },
        {
            "name": "Benin",
            "code": "BJ",
            "continent": "africa",
            "filename": "benin"
        },
        {
            "name": "Bermuda",
            "code": "BM",
            "continent": "america"
        },
        {
            "name": "Bhutan",
            "code": "BT",
            "continent": "asia",
            "filename": "bhutan"
        },
        {
            "name": "Bolivia",
            "code": "BO",
            "continent": "america",
            "filename": "bolivia"
        },
        {
            "name": "Bosnia and Herzegovina",
            "code": "BA",
            "continent": "europe",
            "filename": "bosnia-and-herzegovina"
        },
        {
            "name": "Botswana",
            "code": "BW",
            "continent": "africa",
            "filename": "botswana"
        },
        {
            "name": "Bouvet Island",
            "continent": "Antarctica",
            "code": "BV"
        },
        {
            "name": "Brazil",
            "code": "BR",
            "continent": "america",
            "filename": "brazil"
        },
        {
            "name": "British Indian Ocean Territory",
            "continent": "africa",
            "code": "IO"
        },
        {
            "name": "Brunei Darussalam",
            "code": "BN",
            "continent": "asia",
            "filename": "brunei-darussalam"
        },
        {
            "name": "Bulgaria",
            "code": "BG",
            "continent": "europe",
            "filename": "bulgaria"
        },
        {
            "name": "Burkina Faso",
            "code": "BF",
            "continent": "africa",
            "filename": "burkina-faso"
        },
        {
            "name": "Burundi",
            "code": "BI",
            "continent": "africa",
            "filename": "burundi"
        },
        {
            "name": "Cambodia",
            "code": "KH",
            "continent": "asia",
            "filename": "cambodia"
        },
        {
            "name": "Cameroon",
            "code": "CM",
            "continent": "africa",
            "filename": "cameroon"
        },
        {
            "name": "Canada",
            "code": "CA",
            "continent": "america",
            "filename": "canada"
        },
        {
            "name": "Cape Verde",
            "code": "CV",
            "continent": "africa",
            "filename": "cape-verde"
        },
        {
            "name": "Cayman Islands",
            "code": "KY",
            "continent": "america"
        },
        {
            "name": "Central african Republic",
            "code": "CF",
            "continent": "africa",
            "filename": "central-african-republic"
        },
        {
            "name": "Chad",
            "code": "TD",
            "continent": "africa",
            "filename": "chad"
        },
        {
            "name": "Chile",
            "code": "CL",
            "continent": "america",
            "filename": "chile"
        },
        {
            "name": "China",
            "code": "CN",
            "continent": "asia",
            "filename": "china"
        },
        {
            "name": "Christmas Island",
            "code": "CX",
            "continent": "oceania"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "code": "CC",
            "continent": "oceania"
        },
        {
            "name": "Colombia",
            "code": "CO",
            "continent": "america",
            "filename": "colombia"
        },
        {
            "name": "Comoros",
            "code": "KM",
            "continent": "africa",
            "filename": "comoros"
        },
        {
            "name": "Congo",
            "code": "CG",
            "continent": "africa",
            "filename": "congo"
        },
        {
            "name": "Congo, The Democratic Republic of the",
            "code": "CD",
            "continent": "africa",
            "filename": "congo-the-democratic-republic-of-the"
        },
        {
            "name": "Cook Islands",
            "continent": "oceania",
            "code": "CK"
        },
        {
            "name": "Costa Rica",
            "code": "CR",
            "continent": "america",
            "filename": "costa-rica"
        },
        {
            "name": "Côte d'Ivoire, Republic of",
            "code": "CI",
            "continent": "africa",
            "filename": "cote-d-ivoire-republic-of"
        },
        {
            "name": "Croatia",
            "code": "HR",
            "continent": "europe",
            "filename": "croatia"
        },
        {
            "name": "Cuba",
            "code": "CU",
            "continent": "america",
            "filename": "cuba"
        },
        {
            "name": "Curaçao",
            "code": "CW",
            "continent": "europe"
        },
        {
            "name": "Cyprus",
            "code": "CY",
            "continent": "europe",
            "filename": "cyprus"
        },
        {
            "name": "Czech Republic",
            "code": "CZ",
            "continent": "europe",
            "filename": "czech-republic"
        },
        {
            "name": "Denmark",
            "code": "DK",
            "continent": "europe",
            "filename": "denmark"
        },
        {
            "name": "Djibouti",
            "code": "DJ",
            "continent": "africa",
            "filename": "djibouti"
        },
        {
            "name": "Dominica",
            "code": "DM",
            "continent": "america",
            "filename": "dominica"
        },
        {
            "name": "Dominican Republic",
            "code": "DO",
            "continent": "america",
            "filename": "dominican-republic"
        },
        {
            "name": "Ecuador",
            "code": "EC",
            "continent": "america",
            "filename": "ecuador"
        },
        {
            "name": "Egypt",
            "code": "EG",
            "continent": "africa",
            "filename": "egypt"
        },
        {
            "name": "El Salvador",
            "code": "SV",
            "continent": "america",
            "filename": "el-salvador"
        },
        {
            "name": "Equatorial Guinea",
            "code": "GQ",
            "continent": "africa",
            "filename": "equatorial-guinea"
        },
        {
            "name": "Eritrea",
            "code": "ER",
            "continent": "africa",
            "filename": "eritrea"
        },
        {
            "name": "Estonia",
            "code": "EE",
            "continent": "europe",
            "filename": "estonia"
        },
        {
            "name": "Ethiopia",
            "code": "ET",
            "continent": "africa",
            "filename": "ethiopia"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "code": "FK",
            "continent": "america"
        },
        {
            "name": "Faroe Islands",
            "continent": "europe",
            "code": "FO"
        },
        {
            "name": "Fiji",
            "code": "FJ",
            "continent": "oceania",
            "filename": "fiji"
        },
        {
            "name": "Finland",
            "code": "FI",
            "continent": "europe",
            "filename": "finland"
        },
        {
            "name": "France",
            "code": "FR",
            "continent": "europe",
            "filename": "france"
        },
        {
            "name": "French Guiana",
            "code": "GF",
            "continent": "america"
        },
        {
            "name": "French Polynesia",
            "code": "PF",
            "continent": "oceania"
        },
        {
            "name": "French Southern Territories",
            "continent": "Antarctica",
            "code": "TF"
        },
        {
            "name": "Gabon",
            "code": "GA",
            "continent": "africa",
            "filename": "gabon"
        },
        {
            "name": "Gambia",
            "code": "GM",
            "continent": "africa",
            "filename": "gambia"
        },
        {
            "name": "Georgia",
            "code": "GE",
            "continent": "europe",
            "filename": "georgia"
        },
        {
            "name": "Germany",
            "code": "DE",
            "continent": "europe",
            "filename": "germany"
        },
        {
            "name": "Ghana",
            "code": "GH",
            "continent": "africa",
            "filename": "ghana"
        },
        {
            "name": "Gibraltar",
            "continent": "europe",
            "code": "GI"
        },
        {
            "name": "Greece",
            "code": "GR",
            "continent": "europe",
            "filename": "greece"
        },
        {
            "name": "Greenland",
            "code": "GL",
            "continent": "america",
            "filename": "greenland"
        },
        {
            "name": "Grenada",
            "code": "GD",
            "continent": "america",
            "filename": "grenada"
        },
        {
            "name": "Guadeloupe",
            "code": "GP",
            "continent": "america"
        },
        {
            "name": "Guam",
            "code": "GU",
            "continent": "oceania"
        },
        {
            "name": "Guatemala",
            "code": "GT",
            "continent": "america",
            "filename": "guatemala"
        },
        {
            "name": "Guernsey",
            "continent": "europe",
            "code": "GG"
        },
        {
            "name": "Guinea",
            "code": "GN",
            "continent": "africa",
            "filename": "guinea"
        },
        {
            "name": "Guinea-Bissau",
            "code": "GW",
            "continent": "africa",
            "filename": "guinea-bissau"
        },
        {
            "name": "Guyana",
            "code": "GY",
            "continent": "america",
            "filename": "guyana"
        },
        {
            "name": "Haiti",
            "code": "HT",
            "continent": "america",
            "filename": "haiti"
        },
        {
            "name": "Heard Island and Mcdonald Islands",
            "continent": "oceania",
            "code": "HM"
        },
        {
            "name": "Holy See (Vatican City State)",
            "code": "VA",
            "continent": "europe"
        },
        {
            "name": "Honduras",
            "code": "HN",
            "continent": "america",
            "filename": "honduras"
        },
        {
            "name": "Hong Kong",
            "code": "HK",
            "continent": "asia",
            "filename": "hong-kong"
        },
        {
            "name": "Hungary",
            "code": "HU",
            "continent": "europe",
            "filename": "hungary"
        },
        {
            "name": "Iceland",
            "code": "IS",
            "continent": "europe",
            "filename": "iceland"
        },
        {
            "name": "India",
            "code": "IN",
            "continent": "asia",
            "filename": "india"
        },
        {
            "name": "Indonesia",
            "code": "ID",
            "continent": "asia",
            "filename": "indonesia"
        },
        {
            "name": "Iraq",
            "code": "IQ",
            "continent": "asia",
            "filename": "iraq"
        },
        {
            "name": "Ireland",
            "code": "IE",
            "continent": "europe",
            "filename": "ireland"
        },
        {
            "name": "Isle of Man",
            "continent": "europe",
            "code": "IM"
        },
        // {
        //     "name": "Israel",
        //     "code": "IL",
        //     "continent": "asia",
        //     "filename": "israel"
        // },
        {
            "name": "Italy",
            "code": "IT",
            "continent": "europe",
            "filename": "italy"
        },
        {
            "name": "Jamaica",
            "code": "JM",
            "continent": "america",
            "filename": "jamaica"
        },
        {
            "name": "Japan",
            "code": "JP",
            "continent": "asia",
            "filename": "japan"
        },
        {
            "name": "Jersey",
            "continent": "europe",
            "code": "JE"
        },
        {
            "name": "Jordan",
            "code": "JO",
            "continent": "asia",
            "filename": "jordan"
        },
        {
            "name": "Kazakhstan",
            "code": "KZ",
            "continent": "europe",
            "filename": "kazakhstan"
        },
        {
            "name": "Kenya",
            "code": "KE",
            "continent": "africa",
            "filename": "kenya"
        },
        {
            "name": "Kiribati",
            "code": "KI",
            "continent": "oceania",
            "filename": "kiribati"
        },
        {
            "name": "Korea, Democratic People's Republic of",
            "code": "KP",
            "continent": "asia",
            "filename": "korea-democratic-people-s-republic-of"
        },
        {
            "name": "South Korea",
            "code": "KR",
            "continent": "asia",
            "filename": "korea-republic-of"
        },
        {
            "name": "Kuwait",
            "code": "KW",
            "continent": "asia",
            "filename": "kuwait"
        },
        {
            "name": "Kyrgyzstan",
            "code": "KG",
            "continent": "asia",
            "filename": "kyrgyzstan"
        },
        {
            "name": "Lao People's Democratic Republic",
            "code": "LA",
            "continent": "asia",
            "filename": "lao-people-s-democratic-republic"
        },
        {
            "name": "Latvia",
            "code": "LV",
            "continent": "europe",
            "filename": "latvia"
        },
        {
            "name": "Lebanon",
            "code": "LB",
            "continent": "asia",
            "filename": "lebanon"
        },
        {
            "name": "Lesotho",
            "code": "LS",
            "continent": "africa",
            "filename": "lesotho"
        },
        {
            "name": "Liberia",
            "code": "LR",
            "continent": "africa",
            "filename": "liberia"
        },
        {
            "name": "Libyan Arab Jamahiriya",
            "code": "LY",
            "continent": "africa",
            "filename": "libyan-arab-jamahiriya"
        },
        {
            "name": "Liechtenstein",
            "code": "LI",
            "continent": "europe",
            "filename": "liechtenstein"
        },
        {
            "name": "Lithuania",
            "code": "LT",
            "continent": "europe",
            "filename": "lithuania"
        },
        {
            "name": "Luxembourg",
            "code": "LU",
            "continent": "europe",
            "filename": "luxembourg"
        },
        {
            "name": "Macau",
            "code": "MO",
            "continent": "asia"
        },
        {
            "name": "Macedonia, The Former Yugoslav Republic of",
            "code": "MK",
            "continent": "europe",
            "filename": "macedonia-the-former-yugoslav-republic-of"
        },
        {
            "name": "Madagascar",
            "code": "MG",
            "continent": "africa",
            "filename": "madagascar"
        },
        {
            "name": "Malawi",
            "code": "MW",
            "continent": "africa",
            "filename": "malawi"
        },
        {
            "name": "Malaysia",
            "code": "MY",
            "continent": "asia",
            "filename": "malaysia"
        },
        {
            "name": "Maldives",
            "code": "MV",
            "continent": "asia",
            "filename": "maldives"
        },
        {
            "name": "Mali",
            "code": "ML",
            "continent": "africa",
            "filename": "mali"
        },
        {
            "name": "Malta",
            "code": "MT",
            "continent": "europe",
            "filename": "malta"
        },
        {
            "name": "Marshall Islands",
            "code": "MH",
            "continent": "oceania",
            "filename": "marshall-islands"
        },
        {
            "name": "Martinique",
            "code": "MQ",
            "continent": "america"
        },
        {
            "name": "Mauritania",
            "code": "MR",
            "continent": "africa",
            "filename": "mauritania"
        },
        {
            "name": "Mauritius",
            "code": "MU",
            "continent": "africa",
            "filename": "mauritius"
        },
        {
            "name": "Mayotte",
            "code": "YT",
            "continent": "africa"
        },
        {
            "name": "Mexico",
            "code": "MX",
            "continent": "america",
            "filename": "mexico"
        },
        {
            "name": "Micronesia, Federated States of",
            "code": "FM",
            "continent": "oceania",
            "filename": "micronesia-federated-states-of"
        },
        {
            "name": "Moldova, Republic of",
            "code": "MD",
            "continent": "europe",
            "filename": "moldova-republic-of"
        },
        {
            "name": "Monaco",
            "code": "MC",
            "continent": "europe",
            "filename": "monaco"
        },
        {
            "name": "Mongolia",
            "code": "MN",
            "continent": "asia",
            "filename": "mongolia"
        },
        {
            "name": "Montenegro",
            "code": "ME",
            "continent": "europe",
            "filename": "montenegro"
        },
        {
            "name": "Montserrat",
            "code": "MS",
            "continent": "america"
        },
        {
            "name": "Morocco",
            "code": "MA",
            "continent": "africa",
            "filename": "morocco"
        },
        {
            "name": "Mozambique",
            "code": "MZ",
            "continent": "africa",
            "filename": "mozambique"
        },
        {
            "name": "Myanmar",
            "code": "MM",
            "continent": "asia",
            "filename": "myanmar"
        },
        {
            "name": "Namibia",
            "code": "NA",
            "continent": "africa",
            "filename": "namibia"
        },
        {
            "name": "Nauru",
            "code": "NR",
            "continent": "oceania",
            "filename": "nauru"
        },
        {
            "name": "Nepal",
            "code": "NP",
            "continent": "asia",
            "filename": "nepal"
        },
        {
            "name": "Netherlands",
            "code": "NL",
            "continent": "europe",
            "filename": "netherlands"
        },
        {
            "name": "Netherlands Antilles",
            "code": "AN",
            "continent": "europe"
        },
        {
            "name": "New Caledonia",
            "code": "NC",
            "continent": "oceania"
        },
        {
            "name": "New Zealand",
            "code": "NZ",
            "continent": "oceania",
            "filename": "new-zealand"
        },
        {
            "name": "Nicaragua",
            "code": "NI",
            "continent": "america",
            "filename": "nicaragua"
        },
        {
            "name": "Niger",
            "code": "NE",
            "continent": "africa",
            "filename": "niger"
        },
        {
            "name": "Nigeria",
            "code": "NG",
            "continent": "africa",
            "filename": "nigeria"
        },
        {
            "name": "Niue",
            "continent": "oceania",
            "code": "NU"
        },
        {
            "name": "Norfolk Island",
            "code": "NF",
            "continent": "oceania"
        },
        {
            "name": "Northern Mariana Islands",
            "continent": "oceania",
            "code": "MP"
        },
        {
            "name": "Norway",
            "code": "NO",
            "continent": "europe",
            "filename": "norway"
        },
        {
            "name": "Oman",
            "code": "OM",
            "continent": "asia",
            "filename": "oman"
        },
        {
            "name": "Pakistan",
            "code": "PK",
            "continent": "asia",
            "filename": "pakistan"
        },
        {
            "name": "Palau",
            "code": "PW",
            "continent": "oceania",
            "filename": "palau"
        },
        {
            "name": "Palestinian Territory, Occupied",
            "code": "PS",
            "continent": "asia",
            "filename": "palestinian-territory-occupied"
        },
        {
            "name": "Panama",
            "code": "PA",
            "continent": "america",
            "filename": "panama"
        },
        {
            "name": "Papua New Guinea",
            "code": "PG",
            "continent": "oceania",
            "filename": "papua-new-guinea"
        },
        {
            "name": "Paraguay",
            "code": "PY",
            "continent": "america",
            "filename": "paraguay"
        },
        {
            "name": "Peru",
            "code": "PE",
            "continent": "america",
            "filename": "peru"
        },
        {
            "name": "Philippines",
            "code": "PH",
            "continent": "asia",
            "filename": "philippines"
        },
        {
            "name": "Pitcairn",
            "continent": "oceania",
            "code": "PN"
        },
        {
            "name": "Poland",
            "code": "PL",
            "continent": "europe",
            "filename": "poland"
        },
        {
            "name": "Portugal",
            "code": "PT",
            "continent": "europe",
            "filename": "portugal"
        },
        {
            "name": "Puerto Rico",
            "code": "PR",
            "continent": "america"
        },
        {
            "name": "Qatar",
            "code": "QA",
            "continent": "asia",
            "filename": "qatar"
        },
        {
            "name": "Réunion",
            "code": "RE",
            "continent": "africa"
        },
        {
            "name": "Romania",
            "code": "RO",
            "continent": "europe",
            "filename": "romania"
        },
        {
            "name": "Russia",
            "code": "RU",
            "continent": "europe",
            "filename": "russian-federation"
        },
        {
            "name": "Rwanda",
            "code": "RW",
            "continent": "africa",
            "filename": "rwanda"
        },
        {
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "code": "SH",
            "continent": "africa",
            "filename": "saint-helena-ascension-and-tristan-da-cunha"
        },
        {
            "name": "Saint Kitts and Nevis",
            "code": "KN",
            "continent": "america",
            "filename": "saint-kitts-and-nevis"
        },
        {
            "name": "Saint Lucia",
            "code": "LC",
            "continent": "america",
            "filename": "saint-lucia"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "code": "PM",
            "continent": "america"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "code": "VC",
            "continent": "america",
            "filename": "saint-vincent-and-the-grenadines"
        },
        {
            "name": "Samoa",
            "code": "WS",
            "continent": "oceania",
            "filename": "samoa"
        },
        {
            "name": "San Marino",
            "code": "SM",
            "continent": "europe",
            "filename": "san-marino"
        },
        {
            "name": "Sao Tome And Principe",
            "code": "ST",
            "continent": "africa",
            "filename": "sao-tome-and-principe"
        },
        {
            "name": "Saudi Arabia",
            "code": "SA",
            "continent": "asia",
            "filename": "saudi-arabia"
        },
        {
            "name": "Senegal",
            "code": "SN",
            "continent": "africa",
            "filename": "senegal"
        },
        {
            "name": "Serbia",
            "code": "RS",
            "continent": "europe",
            "filename": "serbia"
        },
        {
            "name": "Seychelles",
            "code": "SC",
            "continent": "africa",
            "filename": "seychelles"
        },
        {
            "name": "Sierra Leone",
            "code": "SL",
            "continent": "africa",
            "filename": "sierra-leone"
        },
        {
            "name": "Singapore",
            "code": "SG",
            "continent": "asia",
            "filename": "singapore"
        },
        {
            "name": "Sint Maarten",
            "code": "SX",
            "continent": "europe"
        },
        {
            "name": "Slovakia",
            "code": "SK",
            "continent": "europe",
            "filename": "slovakia"
        },
        {
            "name": "Slovenia",
            "code": "SI",
            "continent": "europe",
            "filename": "slovenia"
        },
        {
            "name": "Solomon Islands",
            "code": "SB",
            "continent": "oceania",
            "filename": "solomon-islands"
        },
        {
            "name": "Somalia",
            "code": "SO",
            "continent": "africa",
            "filename": "somalia"
        },
        {
            "name": "South africa",
            "code": "ZA",
            "continent": "africa",
            "filename": "south-africa"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "code": "GS",
            "continent": "america"
        },
        {
            "name": "South Sudan",
            "code": "SS",
            "continent": "africa",
            "filename": "south-sudan"
        },
        {
            "name": "Spain",
            "code": "ES",
            "continent": "europe",
            "filename": "spain"
        },
        {
            "name": "Sri Lanka",
            "code": "LK",
            "continent": "asia",
            "filename": "sri-lanka"
        },
        {
            "name": "Sudan",
            "code": "SD",
            "continent": "africa",
            "filename": "sudan"
        },
        {
            "name": "Suriname",
            "code": "SR",
            "continent": "america",
            "filename": "suriname"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "continent": "europe",
            "code": "SJ"
        },
        {
            "name": "Swaziland",
            "code": "SZ",
            "continent": "africa",
            "filename": "swaziland"
        },
        {
            "name": "Sweden",
            "code": "SE",
            "continent": "europe",
            "filename": "sweden"
        },
        {
            "name": "Switzerland",
            "code": "CH",
            "continent": "europe",
            "filename": "switzerland"
        },
        {
            "name": "Syrian Arab Republic",
            "code": "SY",
            "continent": "asia",
            "filename": "syrian-arab-republic"
        },
        {
            "name": "Taiwan",
            "code": "TW",
            "continent": "asia",
            "filename": "taiwan"
        },
        {
            "name": "Tajikistan",
            "code": "TJ",
            "continent": "asia",
            "filename": "tajikistan"
        },
        {
            "name": "Tanzania, United Republic of",
            "code": "TZ",
            "continent": "africa",
            "filename": "tanzania-united-republic-of"
        },
        {
            "name": "Thailand",
            "code": "TH",
            "continent": "asia",
            "filename": "thailand"
        },
        {
            "name": "Timor-Leste",
            "code": "TL",
            "continent": "oceania",
            "filename": "timor-leste"
        },
        {
            "name": "Togo",
            "code": "TG",
            "continent": "africa",
            "filename": "togo"
        },
        {
            "name": "Tokelau",
            "continent": "oceania",
            "code": "TK"
        },
        {
            "name": "Tonga",
            "code": "TO",
            "continent": "oceania",
            "filename": "tonga"
        },
        {
            "name": "Trinidad and Tobago",
            "code": "TT",
            "continent": "america",
            "filename": "trinidad-and-tobago"
        },
        {
            "name": "Tunisia",
            "code": "TN",
            "continent": "africa",
            "filename": "tunisia"
        },
        {
            "name": "Turkey",
            "code": "TR",
            "continent": "europe",
            "filename": "turkey"
        },
        {
            "name": "Turkmenistan",
            "code": "TM",
            "continent": "asia",
            "filename": "turkmenistan"
        },
        {
            "name": "Turks and Caicos Islands",
            "code": "TC",
            "continent": "america"
        },
        {
            "name": "Tuvalu",
            "code": "TV",
            "continent": "oceania",
            "filename": "tuvalu"
        },
        {
            "name": "Uganda",
            "code": "UG",
            "continent": "africa",
            "filename": "uganda"
        },
        {
            "name": "Ukraine",
            "code": "UA",
            "continent": "europe",
            "filename": "ukraine"
        },
        {
            "name": "United Arab Emirates",
            "code": "AE",
            "continent": "asia",
            "filename": "united-arab-emirates"
        },
        {
            "name": "United Kingdom",
            "code": "GB",
            "continent": "europe",
            "filename": "united-kingdom"
        },
        {
            "name": "United States",
            "code": "US",
            "continent": "america",
            "filename": "united-states"
        },
        {
            "name": "United States Minor Outlying Islands",
            "code": "UM",
            "continent": "america",
            "filename": "united-states-minor-outlying-islands"
        },
        {
            "name": "Uruguay",
            "code": "UY",
            "continent": "america",
            "filename": "uruguay"
        },
        {
            "name": "Uzbekistan",
            "code": "UZ",
            "continent": "asia",
            "filename": "uzbekistan"
        },
        {
            "name": "Vanuatu",
            "code": "VU",
            "continent": "oceania",
            "filename": "vanuatu"
        },
        {
            "name": "Venezuela",
            "code": "VE",
            "continent": "america",
            "filename": "venezuela"
        },
        {
            "name": "Viet Nam",
            "code": "VN",
            "continent": "asia",
            "filename": "viet-nam"
        },
        {
            "name": "Virgin Islands, British",
            "code": "VG",
            "continent": "america"
        },
        {
            "name": "Virgin Islands, U.S.",
            "code": "VI",
            "continent": "america"
        },
        {
            "name": "Wallis and Futuna",
            "continent": "oceania",
            "code": "WF"
        },
        {
            "name": "Western Sahara",
            "continent": "africa",
            "code": "EH"
        },
        {
            "name": "Yemen",
            "code": "YE",
            "continent": "asia",
            "filename": "yemen"
        },
        {
            "name": "Zambia",
            "code": "ZM",
            "continent": "africa",
            "filename": "zambia"
        },
        {
            "name": "Zimbabwe",
            "code": "ZW",
            "continent": "africa",
            "filename": "zimbabwe"
        }
    ],
};

export default static_country;